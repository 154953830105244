export const black = "#000000"
export const lightBlack = "#1D1D1B"
export const offBlack = "#303030"
export const grey = "#f7f9f7"
export const lightGrey = "#979797"
export const greyLight = "#e0ded8"
export const greyDark = "#7f7f7f"
export const offGrey = "#d8d8d8"
export const offGreyLight = "#d9d9d9"
export const inputLabelGrey = "#737373"
export const inputLabelGreyHighlight = "#545454"
export const borderGrey = "#ebeced"
export const otherBorderGrey = "#dddddd"
export const primaryDark = "#105d6e"
export const primaryDarkShade = "rgba(16,93,110,0.09)"
export const primaryDarkTwo = "#0c414b"
export const primaryLight = "#2faeaf"
export const primaryLightLight = "#5ccfd0"
export const skyBlue = "#36A9AD"
export const mustard = "#dbac48"
export const rouge = "#c7566f"
export const white = "#ffffff"
export const offWhite = "#fffef4"
export const lightGray = "#f2f2f2"
export const darkGrey = "#a3a4a5"

// NEW COLORS
export const primaryDarkerHighlight = "#00465a"
export const primaryDarker = "#003648"
export const primaryEvenDarker = "#002b38"
export const sidebarGrey = "#f9f9f9"
export const sidebarSelectedGrey = "#dfe4e6"
export const sidebarHoverGrey = "#e6e6e6"
export const sidebarBorderGrey = "#e0e0e0"
export const darkBorderGrey = "#6b6c6d"
export const backgroundGrey = "#f9f9f9"
export const imageGrey = "#f5f5f5"
export const unselectedTextGrey = "rgb(136, 136, 136)"
export const linkHoverBlue = "rgb(0, 54, 72)"
export const pipeGrey = "rgb(230, 230, 230);"
export const textGrey = "rgb(102, 102, 102)"
export const formTextBlack = "#333333"
// END NEW COLORS

export const text = "hsla(0,0%,0%,0.8)"
export const linkBlue = "#4183c4"
export const darkBlue = "#00328c"
export const backgroundGray = "#f5f3f6"
export const ocher = "#f0be48"
export const cyan = "#00bac5"
export const lightPink = "#f66588"
export const lighterBlack = "#c4c4bf"
export const textBlack = "#555555"
export const toggleGrey = "#8d8d8d"
export const green = "#33691e"

export const boxShadow =
  "0 0 5px 0 rgba(0, 0, 0, 0.3), 0 0 1px -2px rgba(0, 0, 0, 0.3),\n    0 0 5px 0 rgba(0, 0, 0, 0.3);"
