import sbjs from "sourcebuster"
import AddressType from "../model/AddressType"
import RecipientType from "../model/RecipientType"

export const isRunTime = (): boolean => {
  return typeof window !== "undefined"
}

export const isUndefined = (object: any): boolean => {
  return typeof object !== "undefined"
}

export const lockScroll = (lock: boolean): void => {
  if (typeof window !== "undefined") {
    document.documentElement.style.overflowY = lock ? "hidden" : "auto"
    document.documentElement.style.height = lock ? "100%" : "100%"
  }
}

export const nl2br = (text: string): string => {
  return text ? `${text}`.replace(/(?:\r\n|\r|\n)/g, "<br/>") : ""
}

export const equalsIgnoreCase = (s1: string, s2: string) => {
  if (!s1 && !s2) {
    return true
  }

  if ((!s1 && s2) || (!s2 && s1)) {
    return false
  }
  return cleanString(s1) === cleanString(s2)
}

export const debounce = (func: Function, timeout: number = 300) => {
  let timer: number
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = window.setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const isANumber = (thing: any): boolean => {
  return !isNotANumber(thing)
}

export const isNotANumber = (thing: any): boolean => {
  return isNaN(parseFloat(thing))
}

export const cleanString = (name: string): string => {
  return name
    .replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "")
    .replace(/ /g, "-")
    .toLowerCase()
}

export const deepEquals = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)

export const addressesAreTheSame = (a: AddressType, b: AddressType) => {
  return (
    a.address1 === b.address1 &&
    a.address2 === b.address2 &&
    a.company === b.company &&
    a.city === b.city &&
    a.country === b.country &&
    a.postCode === b.postCode &&
    a.firstName === b.firstName &&
    a.lastName === b.lastName
  )
}

export const recipientsAreTheSame = (a: RecipientType, b: RecipientType) => {
  return addressesAreTheSame(a.address, b.address) && a.email === b.email && a.phone === b.phone
}

export const getSourceTrackingInfo = () => {
  if (sbjs?.get) {
    const { current, first } = sbjs.get
    return {
      first: {
        utmSource: first.src,
        utmMedium: first.mdm,
        utmCampaign: first.cmp,
        utmContent: first.cnt,
        utmTerm: first.trm,
      },
      current: {
        utmSource: current.src,
        utmMedium: current.mdm,
        utmCampaign: current.cmp,
        utmContent: current.cnt,
        utmTerm: current.trm,
      },
    }
  } else {
    return { first: {}, current: {} }
  }
}
