import { getPriceRange, parsePrice } from "./productPriceUtil"
import BasketItemType from "../model/BasketItemType"

export const getBasketSubtotal = (items: BasketItemType[]): number => {
  let total = 0
  items.forEach(item => {
    total += getItemPrice(item) * item.quantity
  })
  return total
}

export const getItemPrice = (item: BasketItemType): number => {
  const priceRange = getPriceRange(
    item.product,
    item.variant ? [item.variant] : null,
    item.quantity
  )
  return priceRange[0]
}
