import { MetaFieldType } from "../model/MetaFieldType"

export const mapRowToRecipient = (row, metaFields: MetaFieldType[]) => {
  let index = 11

  const metaValues = {}
  metaFields.forEach(metaField => {
    metaValues[metaField.name] = row[index]
    index++
  })

  return {
    address: {
      firstName: row[0],
      lastName: row[1],
      address1: row[2],
      address2: row[3],
      company: row[4],
      city: row[5],
      country: "GB",
      postCode: row[6],
    },
    email: row[7],
    phone: row[8],
    giftNote: row[9],
    deliveryNote: row[10],
    metaValues,
  }
}
