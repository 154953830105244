import ImageType from "../model/ImageType"

export const clearDuplicateImages = (images: ImageType[]): ImageType[] => {
  const sourceUrls: string[] = []

  return images.filter(image => {
    if (image.src && sourceUrls.includes(image.src)) {
      return false
    } else if (image.src) {
      sourceUrls.push(image.src)
      return true
    }
  })
}
