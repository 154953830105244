import SelectionPageConfigType from "../model/SelectionPageConfigType"
import ProductSelectionIdType from "../model/ProductSelectionIdType"
import { get, platformGet, platformPost, post } from "./general"
import AddressType from "../model/AddressType"
import RecipientType from "../model/RecipientType"

export const getPublicOptions = async (
  baseUrl: string,
  orderKey: string
): Promise<SelectionPageConfigType> => {
  const response = await get(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/public-options?orderKey=${orderKey}`
  )
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw response.message
  }
}

interface ConfigResponseType {
  data: SelectionPageConfigType
  status: string
  message: string
}
export const getPrivateOptions = async (
  baseUrl: string,
  recipientKey: string
): Promise<ConfigResponseType> => {
  return await get(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/private-options?recipientKey=${recipientKey}`
  )
}

export const newPublicRecipient = async (
  baseUrl: string,
  orderKey: string,
  email: string,
  phone: string,
  selection: ProductSelectionIdType,
  address: AddressType,
  metaValues: { [key: string]: string }
) => {
  const response = await post(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/new-public-recipient`,
    {
      orderKey,
      email,
      phone,
      selection,
      address,
      metaValues,
    }
  )
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw response.message
  }
}

export const newMultiRecipients = async (
  baseUrl: string,
  orderKey: string,
  senderName: string,
  senderEmail: string,
  selection: ProductSelectionIdType,
  metaValues: { [key: string]: string },
  recipients: RecipientType[]
) => {
  const response = await post(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/new-multiple-recipients`,
    {
      orderKey,
      senderName,
      senderEmail,
      selection,
      metaValues,
      recipients,
    }
  )
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw response.message
  }
}

export const downloadTemplate = async (
  baseUrl: string,
  orderKey: string,
  availableCountries: string[]
) => {
  const fileName = `address-template.xlsx`

  return fetch(`${baseUrl}/wp-json/social-supermarket/v1/recipient/template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderKey,
      availableCountries,
    }),
  })
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
    .catch(e => console.log(e))
}

export const newPrivateRecipients = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  senderName: string,
  giftMessage: string,
  emails: string[],
  url: string,
  metaValues: { [key: string]: string }
) => {
  return platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/new-private-recipients`,
    {
      orderKey,
      senderName,
      giftMessage,
      emails,
      url,
      metaValues,
    },
    token
  )
}

export const getRecipients = (
  baseUrl: string,
  token: string,
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT",
  userId: number
) => {
  return platformGet(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/get-recipients?category=${category}&userId=${userId}`,
    token
  )
}

export const reSendInvites = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  url: string
) => {
  return platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/re-invite`,
    {
      orderKey,
      url,
    },
    token
  )
}

export const updatePrivateRecipient = async (
  baseUrl: string,
  recipientKey: string,
  phone: string,
  selection: ProductSelectionIdType,
  address: AddressType,
  metaValues: { [key: string]: string }
) => {
  const response = await post(
    `${baseUrl}/wp-json/social-supermarket/v1/recipient/update-private-recipient`,
    {
      recipientKey,
      phone,
      selection,
      address,
      metaValues,
    }
  )
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw response.message
  }
}
