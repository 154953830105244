import * as api from "./general"
import BasketItemType from "../model/BasketItemType"
import BasketType from "../model/BasketType"
import AddressType from "../model/AddressType"

export const create = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  responseIds: number[]
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/create`,
    { orderKey, responseIds },
    token
  )
}

export const updateDeliveryDate = async (
  baseUrl: string,
  token: string,
  recipientIds: number[],
  vendorSlug: string,
  newDate: string
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/update-delivery-date`,
    { recipientIds, vendorSlug, newDate },
    token
  )
}

export const total = async (baseUrl: string, token: string, total: number) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/total`,
    { total },
    token
  )
}

export const adminApprove = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  responseIds: number[]
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/approve-recipients`,
    { orderKey, responseIds },
    token
  )
}
export const initInvoice = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  recipientIds: number[]
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/init-invoice`,
    { orderKey, recipientIds },
    token
  )
}
export const invoice = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  recipientIds: number[],
  reference: string
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/invoice`,
    { orderKey, recipientIds, reference },
    token
  )
}

export const deleteResponses = async (
  baseUrl: string,
  token: string,
  orderKey: string,
  responseIds: number[]
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/checkout/delete`,
    { orderKey, responseIds },
    token
  )
}

export const getShipping = async (baseUrl: string, token: string, items: BasketItemType[]) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/basket/get-shipping`,
    {
      items: items.map(item => ({
        productId: item.product.productId,
        variantId: item.variant?.variantId,
        quantity: item.quantity,
      })),
    },
    token
  )
}

export const saveBasket = async (
  baseUrl: string,
  token: string,
  basket: BasketType,
  billingAddress: AddressType,
  shopfront: string,
  userId: number
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/basket/save-basket`,
    {
      items: basket.items.map(item => ({
        productId: item.product.productId,
        variantId: item.variant?.variantId,
        quantity: item.quantity,
        deliveryDate: item.deliveryDate,
        shippingMethod: item.shippingMethod,
      })),
      recipients: basket.recipients,
      hasApproved: basket.hasApproved,
      billingAddress,
      category: basket.category,
      userId,
      reference: basket.reference,
      shopfront,
    },
    token
  )
}

export const getBasket = async (
  baseUrl: string,
  token: string,
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT",
  userId: number
) => {
  return api.platformGet(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/basket/get-basket?category=${category}&userId=${userId}`,
    token
  )
}

export const completeBasket = async (
  baseUrl: string,
  token: string,
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT",
  userId: number
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/basket/complete`,
    {
      category,
      userId,
    },
    token
  )
}

export const completeAndInvoiceBasket = async (
  baseUrl: string,
  token: string,
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT",
  userId: number
) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/basket/complete-invoice`,
    {
      category,
      userId,
    },
    token
  )
}
