export const spinnerZIndex = 1000

export const modalBackgroundZIndex = 710
export const modalBackgroundShadeZIndex = 710

export const backToTopButtonZIndex = 600
export const profileZIndex = 600

export const headerZIndex = 100
export const sidebarMenuZIndex = 100
export const subMenuBarZIndex = 100
export const productFilterListSidebarZIndex = 100
export const sideTabsButtonsZIndex = 100

export const giftingPageTabsZIndex = 100

export const choiceSelectionItemZIndex = 50

export const choiceSelectionContentsZIndex = 30
export const choiceSelectionContentsShadeZIndex = 10

export const productCardButtonsZIndex = 20
export const priceFilterHandleHoverOverlayZIndex = 20

export const productImageGalleryZoomboxZIndex = 10
export const productImageGalleryZoomImageZIndex = 10

export const outlinedInputLabelZIndex = 1
