import { getConfig } from "../../config/index"
import FavoriteType from "../model/FavoriteType"
import { api } from "model"

export const getFavorites = async (token: string, userId: number): Promise<FavoriteType[]> => {
  return api.platformGet(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/favorites/get-favorites?userId=${userId}`,
    token
  )
}

export const addFavorite = async (
  productId: number,
  category: string,
  token: string,
  userId: number
) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/favorites/add-favorite`,
    {
      productId,
      category,
      userId,
    },
    token
  )
}
export const removeFavorite = async (
  productId: number,
  category: string,
  token: string,
  userId: number
) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/favorites/remove-favorite`,
    {
      productId,
      category,
      userId,
    },
    token
  )
}
