interface AddressOptionType {
  address1: string
  address2: string
}

interface AddressType {
  contactEmail?: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  company: string
  postCode: string
  city: string
  country: string
  addressOptions?: AddressOptionType[]
}

export const DEFAULT_ADDRESS_STATE: AddressType = {
  contactEmail: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  company: "",
  postCode: "",
  city: "",
  country: "GB",
  addressOptions: [],
}

export default AddressType
