import { isRunTime } from "../util/generalUtil"
import { VariantType, parsePrice } from "model"

declare global {
  interface Window {
    dataLayer: any
  }
}

export const variantSelected = (variant: VariantType) => {
  track({
    event: "variantSelected",
    value: parsePrice(variant.price),
    items: [{ id: variant.variantId, google_business_vertical: "retail" }],
  })
}

export const platformRegister = () => {
  track({
    event: "platform-register",
    ecommerce: undefined,
  })
}

export const track = object => {
  if (canTrack()) {
    window.dataLayer.push(object)
  }
}

const canTrack = (): boolean => {
  return isRunTime() && window.dataLayer
}
