import { grey, offWhite, primaryDark, primaryLight, green, rouge } from "../constants/colors"

export const DEFAULT_THEME_TYPE = {
  primary: primaryLight,
  headerBackground: grey,
  background: offWhite,
  controls: primaryLight,
  controlsDark: primaryDark,
  invertedControls: primaryDark,
  inStock: green,
  outOfStock: rouge,
  font: "GillSans",
}

export const DEFAULT_THEME_CONTEXT_TYPE = {
  theme: { ...DEFAULT_THEME_TYPE },
  setTheme: () => null,
}

export type ThemeContextType = {
  setTheme: (theme: ThemeType) => void
  theme: ThemeType
}

export default interface ThemeType {
  primary: string
  headerBackground: string
  background: string
  controls: string
  controlsDark: string
  invertedControls: string
  inStock: string
  outOfStock: string
  font: string
}
