import local from "./local"
import dev from "./dev"
import uat from "./uat"
import prod from "./prod"

export const getConfig = (url?: string) => {
  if (typeof window === "undefined" && !url) {
    return prod
  }
  let href = ""
  if (url) {
    href = url
  } else if (typeof window !== "undefined") {
    href = window?.location?.href
  }

  if (href.includes("locallocal.")) {
    return local
  } else if (href.includes("localdev.")) {
    return dev
  } else if (
    href.includes("localuat.") ||
    href.includes("gatsbyjs.io") ||
    href.includes("gtsb.io") ||
    href.includes("gifting-qa") ||
    href.includes("gifting-demo") ||
    href.includes("app-demo") ||
    href.includes("localhost")
  ) {
    return uat
  } else {
    return prod
  }
}

export const getFrontendUrl = () => {
  const { protocol, host } = window.location
  return `${protocol}//${host}`
}
