import BasketItemType from "../model/BasketItemType"
import VendorType from "../model/VendorType"

const CUTOFF = "13:00"

export const getDaysAgo = (date: string): number => {
  let today = new Date()
  let createdOn = new Date(date)
  let msInDay = 24 * 60 * 60 * 1000

  createdOn.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return Math.floor((+today - +createdOn) / msInDay)
}

export const formatDate = dateString => {
  const date = new Date(dateString)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const isDateInTheFuture = dateString => {
  const [year, month, day] = dateString.split("-")
  const inputDate = new Date(year, month - 1, day)
  const currentDate = new Date()
  return inputDate > currentDate
}

export const getBusinessDaysAhead = (days: number): Date => {
  const today = new Date()
  return addWorkDays(today, days)
}

export const getDaysAhead = (days: number): Date => {
  const today = new Date()
  return addDays(today, days)
}

const addWorkDays = (startDate: Date, daysToAdd: number): Date => {
  let dow = startDate.getDay()

  if (dow === 0) {
    daysToAdd++
  }

  if (dow + daysToAdd >= 6) {
    let remainingWorkDays = daysToAdd - (5 - dow)
    daysToAdd += 2
    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5)
      if (remainingWorkDays % 5 === 0) {
        daysToAdd -= 2
      }
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const addDays = (startDate: Date, daysToAdd: number): Date => {
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const getTwoDigitsFormat = (value: number): number | string => (value < 10 ? `0${value}` : value)

export const checkIsDeliveryEnabled = (date: Date, bankHolidays: string[]): boolean => {
  const day = date.getDay()
  const year = date.getFullYear()
  const month = getTwoDigitsFormat(date.getMonth() + 1)
  const dateValue = getTwoDigitsFormat(date.getDate())
  const formattedDate = `${year}-${month}-${dateValue}`

  return day !== 0 && day !== 6 && !bankHolidays.includes(formattedDate)
}

const getLatestSoonestDeliveryDate = (items: BasketItemType[]): string => {
  let latestDate: string = null

  items.forEach(item => {
    if (item.product.soonestDeliveryDate) {
      if (latestDate) {
        if (item.product.soonestDeliveryDate > latestDate) {
          latestDate = item.product.soonestDeliveryDate
        }
      } else {
        latestDate = item.product.soonestDeliveryDate
      }
    }
  })

  return latestDate
}

export const getDeliveryLeadTime = (items: BasketItemType[], vendor: VendorType) => {
  let highestLeadTime = vendor?.nominatedDayDelay || 1
  items.forEach(item => {
    if (item.product.deliveryLeadTime && item.product.deliveryLeadTime > highestLeadTime) {
      highestLeadTime = item.product.deliveryLeadTime
    }
  })
  return highestLeadTime
}

export const getShippingMethod = (targetDateString: string): "STANDARD" | "EXPRESS" => {
  const targetDate = new Date(targetDateString)
  const now = new Date()

  const [cutoffHour, cutoffMinute] = CUTOFF.split(":").map(Number)
  const cutoffTime = new Date(now)
  cutoffTime.setHours(cutoffHour, cutoffMinute, 0, 0)

  const currentDay = now.getDay()
  const isWeekend = currentDay === 0 || currentDay === 6
  const afterCutoff = now > cutoffTime

  // Decide how many weekdays to skip
  let weekdayOffset = 1
  if (isWeekend || afterCutoff) {
    weekdayOffset = 2
  }

  // Find the correct target weekday
  let nextWeekday = new Date(now)
  let added = 0
  while (added < weekdayOffset) {
    nextWeekday.setDate(nextWeekday.getDate() + 1)
    if (![0, 6].includes(nextWeekday.getDay())) {
      added++
    }
  }

  return targetDate.getDay() === nextWeekday.getDay() ? "EXPRESS" : "STANDARD"
}

export const getSoonestDeliveryDate = (
  bankHolidays: string[] = [],
  items: BasketItemType[],
  isSuperAdmin: boolean = false,
  vendor?: VendorType
): Date => {
  let currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay()
  const ukNow = new Date(currentDate.toLocaleString("en-GB", { timeZone: "Europe/London" }))
  let deliveryLeadTime = getDeliveryLeadTime(items, vendor)

  const [cutoffHour, cutoffMinute] = CUTOFF.split(":").map(Number)
  const cutoffTime = new Date(ukNow)
  cutoffTime.setHours(cutoffHour, cutoffMinute, 0, 0)

  const afterCutoff = ukNow > cutoffTime

  if (afterCutoff || [0, 6].includes(currentDayOfWeek)) {
    deliveryLeadTime += 1
  }

  do {
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    if (!checkIsDeliveryEnabled(currentDate, bankHolidays)) {
      continue
    }
    deliveryLeadTime -= 1
  } while (deliveryLeadTime !== 0)

  const latestSoonestDeliveryDateString = getLatestSoonestDeliveryDate(items)

  if (latestSoonestDeliveryDateString) {
    const latestSoonestDeliveryDate: Date = new Date(latestSoonestDeliveryDateString)

    if (latestSoonestDeliveryDate.getTime() > currentDate.getTime()) {
      return latestSoonestDeliveryDate
    }
  }

  return currentDate
}
