export const DEFAULT_REGISTER_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  password: "",
  phone: "",
  hearAboutUs: "",
  optIn: false,
}

export default interface RegisterFormType {
  firstName: string
  lastName: string
  email: string
  company: string
  password: string
  phone: string
  hearAboutUs: string
  optIn: boolean
}
