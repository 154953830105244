import BasketItemType from "./BasketItemType"
import RecipientType from "./RecipientType"

export const DEFAULT_BASKET: BasketType = {
  userId: 0,
  items: [],
  recipients: [],
  hasApproved: false,
  paymentIntentId: null,
  clientSecret: "",
  exVatSubTotal: 0,
  subTotal: 0,
  shippingExVatTotal: 0,
  shippingTotal: 0,
  exVatTotal: 0,
  total: 0,
  category: "GIFTING",
  reference: "",
  needsSaving: false,
}

interface BasketType {
  userId: number
  items: BasketItemType[]
  recipients: RecipientType[]
  hasApproved: boolean
  paymentIntentId: string
  clientSecret: string
  exVatSubTotal: number
  subTotal: number
  shippingExVatTotal: number
  shippingTotal: number
  exVatTotal: number
  total: number
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT"
  reference: string
  needsSaving: boolean
}

export default BasketType
