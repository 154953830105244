import * as api from "./general"
import RegisterFormType from "../model/RegisterFormType"
import ShopfrontType from "../model/ShopfrontType"
import CustomerType from "../model/CustomerType"
import AddressType from "../model/AddressType"

export const login = async (baseUrl: string, username: string, password: string): Promise<any> => {
  return api.post(`${baseUrl}/wp-json/jwt-auth/v1/token`, {
    username,
    password,
  })
}

export const register = async (
  baseUrl: string,
  formState: RegisterFormType,
  shopfront?: ShopfrontType,
  utm?: object
) => {
  return api.post(`${baseUrl}/wp-json/social-supermarket/v1/login/register`, {
    formState,
    shopfrontSlug: shopfront?.slug,
    utm,
  })
}

export const maybeRegister = async (
  baseUrl: string,
  formState: RegisterFormType,
  shopfront?: ShopfrontType
) => {
  return api.post(`${baseUrl}/wp-json/social-supermarket/v1/login/maybe-register`, {
    formState,
    shopfrontSlug: shopfront?.slug,
  })
}

export const getCurrentUser = async (baseUrl: string, token: string): Promise<CustomerType> => {
  const response = await api.loggedInGet(
    `${baseUrl}/wp-json/social-supermarket/v1/login/get-current`,
    token
  )
  if (response.status === "success") {
    return response.user
  } else {
    return null
  }
}

export const searchCustomers = async (
  baseUrl: string,
  token: string,
  query: string
): Promise<CustomerType[]> => {
  const response = await api.loggedInGet(
    `${baseUrl}/wp-json/social-supermarket/v1/login/search?query=${query}`,
    token
  )
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    console.log("ERROR", response)
    throw response.message
  }
}

export const updatePassword = async (
  baseUrl: string,
  token: string,
  userId: number,
  password: string
): Promise<void> => {
  return api.loggedInPost(
    `${baseUrl}/wp-json/social-supermarket/v1/login/update-password`,
    {
      password,
      userId,
    },
    token
  )
}

export const requestPasswordReset = async (baseUrl: string, email: string) => {
  return api.post(`${baseUrl}/wp-json/bdpwr/v1/reset-password`, {
    email,
  })
}

export const setNewPassword = async (baseUrl: string, email, code, password) => {
  return api.post(`${baseUrl}/wp-json/bdpwr/v1/set-password`, {
    email,
    code,
    password,
  })
}

export const addBillingAddress = async (baseUrl: string, token: string, address: AddressType) => {
  return api.loggedInPost(
    `${baseUrl}/wp-json/social-supermarket/v1/add-billing`,
    {
      address,
    },
    token
  )
}

export const updateLogo = async (baseUrl: string, token: string, userId: number, file: File) => {
  let data = new FormData()
  data.append("0", file)
  data.append("userId", `${userId}`)

  return fetch(`${baseUrl}/wp-json/social-supermarket/v1/login/update-logo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    credentials: "include",
    body: data,
  }).then(ar => ar.json())
}
