import CustomerType from "./CustomerType"
import PunchOutConfigType from "./PunchOutConfigType"

export type LoginContextType = {
  allowsInvoice: boolean
  error: string
  initialising: boolean
  isAdmin: boolean
  loggedInUser: CustomerType
  loggingIn: boolean
  loggingOut: boolean
  login: (token: string) => void
  onLogOut: () => void
  onLogin: (email: string, password: string) => void
  punchOutConfig?: PunchOutConfigType
  setUser: (user: CustomerType) => void
  token: string
  user: CustomerType
}

export const DEFAULT_LOGIN_CONTEXT_TYPE: LoginContextType = {
  allowsInvoice: false,
  error: "",
  initialising: true,
  isAdmin: false,
  loggedInUser: null,
  loggingIn: false,
  loggingOut: false,
  login: () => null,
  onLogOut: () => null,
  onLogin: () => {},
  punchOutConfig: null,
  setUser: () => null,
  token: "",
  user: null,
}
