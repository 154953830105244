import ProductType from "../model/ProductType"
import VariantType from "../model/VariantType"
import VolumeDiscountType from "../model/VolumeDiscountType"

export const parsePrice = (price: string | number): number => {
  const p = parseFloat(`${price}`.replace("£", "").replace(/,/g, ""))
  return isNaN(p) ? 0 : p
}

export const formatPrice = (price: string | number) => {
  const priceFloat = parsePrice(price).toFixed(2)
  return `£${priceFloat}`
}

export const roundHalfUp = (num: number): number => {
  return Number(num.toFixed(2))
}

export const getFormattedPriceRange = (
  product: ProductType,
  variants?: VariantType[],
  quantity?: number,
  exVat?: boolean,
  incShipping?: boolean
): string => {
  const range = getPriceRange(product, variants, quantity, exVat, incShipping)
  return formatPriceRange(range)
}
export const getPriceRange = (
  product: ProductType,
  variants?: VariantType[],
  quantity?: number,
  exVat?: boolean,
  incShipping?: boolean
): [number, number] => {
  let range: [number, number] = [0, 0]
  if (variants && variants.length > 0) {
    range = getPriceRangeFromVariants(variants, product.volumeDiscounts, quantity, exVat)
  } else if (product.variants?.length > 0) {
    range = getPriceRangeFromVariants(product.variants, product.volumeDiscounts, quantity, exVat)
  } else {
    const productPrice = exVat ? product.exVatPrice : product.price
    if (quantity) {
      const bestPrice = getBestPrice(productPrice, product.volumeDiscounts, quantity)
      range = [bestPrice, bestPrice]
    } else {
      range = getRangeFromPrice(productPrice, product.volumeDiscounts)
    }
  }

  if (incShipping) {
    range[0] = range[0] + product.singleShippingCost
    range[1] = range[1] + product.singleShippingCost
  }

  return range
}

export const formatPriceRange = (range: [number, number]): string => {
  if (range[0] === range[1]) {
    return formatPrice(range[0])
  } else {
    return `${formatPrice(range[0])} - ${formatPrice(range[1])}`
  }
}

const getBestPrice = (
  originalPrice: number,
  discounts: VolumeDiscountType[] = [],
  quantity: number
) => {
  let bestPrice = originalPrice
  discounts.forEach(discount => {
    if (quantity >= discount.quantity) {
      const discountPrice = getPriceFromDiscount(originalPrice, discount.discount)
      if (discountPrice < bestPrice) {
        bestPrice = discountPrice
      }
    }
  })
  return bestPrice
}

const getPriceRangeFromVariants = (
  variants: VariantType[],
  discounts: VolumeDiscountType[],
  quantity?: number,
  exVat?: boolean
): [number, number] => {
  let min = undefined
  let max = undefined
  variants.forEach(variant => {
    let range = [0, 0]
    if (quantity) {
      const bestPrice = getBestPrice(
        exVat ? variant.exVatPrice : variant.price,
        discounts,
        quantity
      )
      range = [bestPrice, bestPrice]
    } else {
      range = getRangeFromPrice(exVat ? variant.exVatPrice : variant.price, discounts)
    }
    if (!min || range[0] < min) {
      min = range[0]
    }
    if (!max || range[1] > max) {
      max = range[1]
    }
  })

  return [min, max]
}

const getRangeFromPrice = (
  price: number,
  discounts: VolumeDiscountType[] = []
): [number, number] => {
  let min = price
  discounts.forEach(discount => {
    const discountedPrice = getPriceFromDiscount(price, discount.discount)
    if (discountedPrice < min) {
      min = discountedPrice
    }
  })
  return [min, price]
}

export const getPriceFromDiscount = (price: number, discount: number): number => {
  return roundHalfUp(price - price * (discount / 100))
}
