export const get = async url => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(ar => ar.json())
}

export const getJson = url => {
  return fetch(url, {
    method: "GET",
  }).then(ar => ar.json())
}

export const loggedInGet = async (url: string, token: string): Promise<any> => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "include",
  }).then(ar => ar.json())
}

export const post = async (url: string, body?: any) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(ar => ar.json())
}

export const loggedInPost = async (url: string, body: any, token: string) => {
  console.log("MAKING POST")
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(ar => {
    console.log("GOT RAW RESPONSE")
    return ar.json()
  }).then((data) => {
    console.log("GOT JSON")
    return data
  })
}

export const platformPost = async (url: string, body: any, token: string) => {
  const response = await loggedInPost(url, body, token)
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw `Unexpected error, please contact support: ${response.message}`
  }
}

export const platformGet = async (url: string, token: string) => {
  const response = await loggedInGet(url, token)
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw `Unexpected error, please contact support: ${response.message}`
  }
}

export const postNoCreds = async (url, body, args) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    ...args,
  })
}

export const getNoCreds = async (url: string) => {
  const response = await get(url)
  if (response.status === "SUCCESS") {
    return response.data
  } else {
    throw `Unexpected error, please contact support: ${response.message}`
  }
}

export const getAirtable = url => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer patFGH7Lq1ajM7RQY.a673708f3d36f8637a2b955aedbb1a149200dd32d41019165b698c1ff81c79d3",
    },
  }).then(ar => ar.json())
}

export const deleteRequest = (url, body) => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(ar => ar.json())
}
