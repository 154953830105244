import * as api from "./general"
import RecipientType from "../model/RecipientType"

export const generateXeroInvoice = (baseUrl: string, token, paymentIds) => {
  return api.platformPost(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/payments/generate-invoice`,
    {
      paymentIds,
    },
    token
  )
}

export const getPayments = (
  baseUrl: string,
  token: string,
  category: "GIFTING" | "SUPPLIES" | "SHOPFRONT",
  userId: number
) => {
  return api.platformGet(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/payments/get-payments?category=${category}&userId=${userId}`,
    token
  )
}

export const getRecipientsForPayment = async (
  baseUrl: string,
  token: string,
  paymentIntentId: string
): Promise<RecipientType[]> => {
  return api.platformGet(
    `${baseUrl}/wp-json/social-supermarket/v1/platform/payments/get-recipients?paymentIntentId=${paymentIntentId}`,
    token
  )
}

export const downloadPayment = (baseUrl: string, token, data) => {
  const key = data.paymentKey
  const date = data.date
  const orderId = data.ID
  const fileName = `order-${orderId}-${date}.pdf`

  return fetch(`${baseUrl}/wp-json/social-supermarket/v1/receipt/generate?key=${key}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
    credentials: "include",
  })
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = fileName
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
}
