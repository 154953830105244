import { getConfig } from "../../../config/index"
import ProductSelectionType from "../../pages/gift/choice/ProductSelectionType"
import OrderType from "../../model/OrderType"
import { MetaFieldType } from "../../pages/gifting/gift-list/designer/GiftListPageDesignerForm"
import { ThemeType, api } from "model"

export const getOrders = async (token: string, userId: number) => {
  const url = `${
    getConfig().baseUrl
  }/wp-json/social-supermarket/v1/platform/orders?userId=${userId}`
  return api.platformGet(url, token)
}

export const getOrder = async (token: string, key: string) => {
  return api.platformGet(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order?key=${key}`,
    token
  )
}

export const createOrder = async (
  token,
  orderName: string,
  giftNote: string,
  nRecipients: number,
  choice: boolean,
  productSelections: ProductSelectionType[],
  userId: number
): Promise<OrderType> => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/create`,
    {
      orderName,
      nRecipients,
      giftNote,
      choice,
      selections: productSelections.map(selection => ({
        productId: selection.product.productId,
        variantId: selection.variant?.variantId,
      })),
      userId,
    },
    token
  )
}

export const updateOptions = async (
  token: string,
  key: string,
  productSelections: ProductSelectionType[]
) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-options`,
    {
      key,
      selections: productSelections.map(selection => ({
        productId: selection.product.productId,
        variantId: selection.variant?.variantId,
      })),
    },
    token
  )
}

export const toggleOpenPage = async (token: string, key: string, enabled: boolean) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/open-page`,
    {
      key,
      enabled,
    },
    token
  )
}

export const updateVoucher = async (
  token: string,
  key: string,
  voucherEnabled: boolean,
  voucherValue: number
) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/voucher`,
    {
      key,
      voucherEnabled,
      voucherValue,
    },
    token
  )
}

export const removeLogo = (token: string, key: string) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/remove-logo`,
    {
      key,
    },
    token
  )
}

export const updateLogo = async (token: string, key: string, file: File) => {
  let data = new FormData()
  data.append("key", key)
  data.append("0", file)

  return fetch(`${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-logo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    credentials: "include",
    body: data,
  }).then(ar => ar.json())
}

export const removeBanner = (token: string, key: string) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/remove-banner`,
    {
      key,
    },
    token
  )
}

export const updateBanner = async (token: string, key: string, file: File) => {
  let data = new FormData()
  data.append("key", key)
  data.append("0", file)

  return fetch(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-banner`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      credentials: "include",
      body: data,
    }
  ).then(ar => ar.json())
}

export const updateDesign = async (
  token: string,
  key: string,
  name: string,
  description: string,
  giftNote: string,
  metaFields: MetaFieldType[],
  categories: string[],
  theme: ThemeType
) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-design`,
    {
      key,
      name,
      description,
      giftNote,
      metaFields,
      categories,
      theme,
    },
    token
  )
}

export const updateGiftNote = async (token: string, key: string, giftNote: string) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-gift-note`,
    {
      key,
      giftNote,
    },
    token
  )
}

export const updateOrderName = async (token: string, key: string, name: string) => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/order/update-name`,
    {
      key,
      name,
    },
    token
  )
}
