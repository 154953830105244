import { clearDuplicateImages } from "./imageUtil"
import he from "he"
import ProductType from "../model/ProductType"
import ImageType from "../model/ImageType"
import AlgoliaProductType from "../model/AlgoliaProductType"
import VariantType from "../model/VariantType"
import BrandType from "../model/BrandType"
import VendorType from "../model/VendorType"

export const getAllImages = (product: ProductType): ImageType[] => {
  return clearDuplicateImages(
    [
      product.image,
      ...(product.gallery || []),
      ...(product.variants ? product.variants.map(v => v.image) : []),
    ].filter(i => i)
  )
}

export const mapAlgoliaProduct = (
  liteProduct: AlgoliaProductType,
  ringfenced?: { [key: string]: number }
): ProductType => {
  const {
    productId,
    slug,
    name,
    sku,
    brand,
    vendorSlug,
    price,
    originalPrice,
    exVatPrice,
    minPrice,
    maxPrice,
    minExVatPrice,
    maxExVatPrice,
    singleShippingCost,
    hasStock,
    imageSrc,
    secondImageSrc,
    impactImageSrc,
    date,
    visibility,
    isLive,
    isSimple,
    stockQuantity,
    eeaStockQuantity,
    platformShowsStock,
    imageBackground,
    productImpact,
    categories,
    colors,
  } = liteProduct

  const ringfencedStock = ringfenced && ringfenced[sku] ? ringfenced[sku] : 0
  const totalStockQuantity =
    ringfencedStock > 0
      ? stockQuantity > 0
        ? stockQuantity + ringfencedStock
        : ringfencedStock
      : stockQuantity

  const variants: VariantType[] | undefined =
    !isSimple || minPrice !== maxPrice
      ? [
          {
            price: minPrice,
            exVatPrice: minExVatPrice,
          },
          {
            price: maxPrice,
            exVatPrice: maxExVatPrice,
          },
        ]
      : undefined

  const fullBrand: BrandType | undefined = brand
    ? {
        name: brand.name,
        slug: brand.slug,
        impactOneLine: brand.oneLiner,
        impactIcons: brand.impactIcons,
      }
    : undefined

  const fullVendor: VendorType = {
    slug: vendorSlug,
    isLive,
  }

  return {
    productId,
    slug,
    name: he.decode(name),
    sku,
    visibility,
    vendor: fullVendor,
    brand: fullBrand,
    price: variants ? undefined : price,
    originalPrice: variants ? undefined : originalPrice,
    exVatPrice: variants ? undefined : exVatPrice,
    singleShippingCost,
    variants,
    productImpacts: [],
    stockStatus: hasStock || totalStockQuantity > 0 ? "IN_STOCK" : "OUT_OF_STOCK",
    stockQuantity: totalStockQuantity,
    eeaStockLevel: eeaStockQuantity,
    image: {
      src: imageSrc,
      alt: name,
    },
    imageSmall: {
      src: imageSrc,
      alt: name,
    },
    impactImage: {
      src: impactImageSrc,
      alt: name,
    },
    date,
    gallery: secondImageSrc
      ? [
          {
            src: secondImageSrc,
            alt: name,
          },
        ]
      : [],
    availableCountries: ["GB"],
    isSimple,
    platformShowsStock,
    hasStock: hasStock || totalStockQuantity > 0,
    imageBackground,
    productImpact,
    isMerch: categories.some(category => category === "Branded Merchandise"),
    colors,
  }
}
